<template>
  <div>

    <CRow>
      <CCol lg="12">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data Artikel</h4> 
            <a href="/#/artikel/add" class="float-right btn btn-success form-control-sm">Tambah Data Baru</a>

          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deleteBerita }">
        <!-- <table class="table" id="datatable"> -->
          <thead>
            <tr>
              <th style="width:30px">#</th>
              <th>Judul</th>
              <th style="width:80px">Jenis</th>
              <th style="width:80px">Tgl Posting</th>
              <!-- <th>Diposting</th> -->
              <th style="width:50px">Edit</th>
              <th style="width:50px">Hapus</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>  

        </CCardBody>
      </CCard>  
  
      </CCol>
    </CRow>
    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deleteBerita()" color="danger">Hapus</CButton>
      </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
  },
  data: function() {
        return {
          deleteModal : false,
            idselected:"0",
            products:[],
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },
    deleteBerita: function(id){
      // alert(this.idselected);
      axios.delete(process.env.VUE_APP_BASE_URL+"berita/"+this.idselected)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {              
          
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"berita/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "judul_berita" },
                  { "data": "nama_rubrik" },
                  { "data": "tgl_posting" },
                  // { "data": "nickname" },
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            alert(data.id_berita);
                            return "<button @click.prevent='deleteBerita(`"+data.id_berita+"`)' data-id='$r[id_berita]' class='btn btn-sm btn-danger btnhapus btn2' >Hapus2</button>";
                        }, 
                    },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
       const table = $(this.$refs.table).DataTable({
          // $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"berita/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no" },
                  { "data": "judul_berita" },
                  { "data": "nama_rubrik" },
                  { "data": "tgl_posting" },
                  // { "data": "nickname" },
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            // alert(data.id_penomoran);
                            return "<button @click.prevent='deleteBerita(`"+row.id+"`)' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                        }, 
                    },
              ]  
          
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';

          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnhapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id);
              self.confirmDelete(objdata.id)
          });
        // })
      }
      
    }
  }
}
</script>